import { Outlet } from "react-router-dom";
import {
  AcademicCapIcon as AcademicCapIconOutline,
  BookOpenIcon as BookOpenIconOutline,
  UserGroupIcon as UserGroupIconOutline,
  UserCircleIcon as UserCircleIconOutline,
} from "@heroicons/react/outline";
import {
  AcademicCapIcon as AcademicCapIconSolid,
  BookOpenIcon as BookOpenIconSolid,
  UserGroupIcon as UserGroupIconSolid,
  UserCircleIcon as UserCircleIconSolid,
} from "@heroicons/react/solid";
import IosBottomNavigation from "../components/ios/IosBottomNavigation";
import IosBottomNavigationItem from "../components/ios/IosBottomNavigationItem";
import useData from "../contexts/data-auth";
import { useEffect } from "react";

const tabItems = [
  {
    label: "Learn",
    to: "/boxes",
    end: false,
    icon: AcademicCapIconOutline,
    iconActive: AcademicCapIconSolid,
    activeClass: "text-rose-500"
  },
  {
    label: "Books",
    to: "/books",
    end: false,
    icon: BookOpenIconOutline,
    iconActive: BookOpenIconSolid,
    activeClass: "text-sky-500",
  },
  {
    label: "Account",
    to: "/account",
    end: false,
    icon: UserCircleIconOutline,
    iconActive: UserCircleIconSolid,
    activeClass: "text-sky-500",
  },
];

export default function Layout() {
  const { data } = useData();

  return (
    <>
      {data && (
        <div className="bg-gray-100 max-w-4xl mx-auto">
          <Outlet />
          <IosBottomNavigation>
            {tabItems.map((item) => (
              <IosBottomNavigationItem
                label={item.label}
                to={item.to}
                end={item.end}
                icon={item.icon}
                iconActive={item.iconActive}
                activeClass={item.activeClass}
              />
            ))}
          </IosBottomNavigation>
        </div>
      )}
    </>
  );
}
