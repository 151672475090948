import { ChevronRightIcon } from "@heroicons/react/outline";
import {
  BellIcon,
  BookmarkIcon,
  ColorSwatchIcon,
  DatabaseIcon,
  DeviceMobileIcon,
  EmojiHappyIcon,
  FolderIcon,
  GlobeAltIcon,
  LockClosedIcon,
  PhoneIcon,
} from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IosNavbar from "../../components/ios/IosNavbar";
import IosListGroupItem from "../../components/ios/IosListGroupItem";
import IosListGroup from "../../components/ios/IosListGroup";
import useAuth from "../../contexts/use-auth";
import useData from "../../contexts/data-auth";

const items = [
  {
    name: "Saved Messages",
    href: "#",
    iconColor: "bg-blue-500",
    icon: BookmarkIcon,
    extra: "",
  },
  {
    name: "Recent Calls",
    href: "#",
    iconColor: "bg-green-500",
    icon: PhoneIcon,
    extra: "",
  },
  {
    name: "Devices",
    href: "#",
    iconColor: "bg-orange-500",
    icon: DeviceMobileIcon,
    extra: "5",
  },
  {
    name: "Chat Folders",
    href: "#",
    iconColor: "bg-sky-500",
    icon: FolderIcon,
    extra: "",
  },
  {
    name: "Notifications and Sounds",
    href: "#",
    iconColor: "bg-red-500",
    icon: BellIcon,
    extra: "",
  },
  {
    name: "Privacy and Security",
    href: "#",
    iconColor: "bg-gray-500",
    icon: LockClosedIcon,
    extra: "",
  },
  {
    name: "Data and Storage",
    href: "#",
    iconColor: "bg-green-500",
    icon: DatabaseIcon,
    extra: "",
  },
  {
    name: "Appearance",
    href: "/account/appearance",
    iconColor: "bg-blue-500",
    icon: ColorSwatchIcon,
    extra: "Dark",
  },
  {
    name: "Language",
    href: "#",
    iconColor: "bg-purple-500",
    icon: GlobeAltIcon,
    extra: "English",
  },
  {
    name: "Sticker and Emoji",
    href: "#",
    iconColor: "bg-orange-500",
    icon: EmojiHappyIcon,
    extra: "",
  },
];

export default function Account() {
  const navigate = useNavigate();
  const auth = useAuth();
  const { data } = useData();

  const handleLogout = () => {
    auth.signout(() => navigate("/", { replace: true }));
  };

  useEffect(() => {
    // loadMe().then((user: AccountDetails) => setUser(user));
  }, []);

  return (
    <>
      <IosNavbar title={"Account"} />

      {false && (
        <div className="flex justify-center px-6 my-6">
          <div className="">
            <img
              className="h-10 w-10 rounded-full"
              src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </div>
          <div className="px-3">
            <p className="text font-medium text-gray-900 dark:text-gray-200">
              Fabian
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-400">@fabian</p>
          </div>
        </div>
      )}

      {data.account && (
        <>
          <IosListGroup>
            <IosListGroupItem
              title={data.account.username}
              subtitle={data.account.email}
              to="/account/edit"
            />
          </IosListGroup>
          <IosListGroup>
            {React.Children.toArray(
              items.map((item) => (
                <IosListGroupItem
                  key={item.name}
                  title={item.name}
                  subtitle={item.name}
                  icon={item.icon}
                  iconColor={item.iconColor}
                  extra={item.extra}
                />
              ))
            )}
          </IosListGroup>
          <ul
            role="list"
            className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-50/[0.06] rounded-lg mx-3 mb-6 overflow-hidden"
          >
            <li>
              <button
                className="relative w-full py-2 px-3 flex items-center justify-center hover:bg-gray-200 dark:hover:bg-slate-700 focus-within:ring-2 focus-within:ring-inset focus-within:ring-teal-600"
                onClick={handleLogout}
              >
                <span className="absolute inset-0" aria-hidden="true" />
                <div className="text-red-600">Sign Out</div>
              </button>
            </li>
          </ul>
        </>
      )}
    </>
  );
}
