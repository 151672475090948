import { ArchiveIcon } from '@heroicons/react/solid'
import moment, { DurationInputArg1, DurationInputArg2 } from 'moment'
import React, { useEffect, useState } from 'react'
import VocabularyDetails from '../business/dto/vocabulary-details'
import IosBottomContext from '../components/ios/IosBottomContext'
import IosListGroup from '../components/ios/IosListGroup'
import IosListGroupItem from '../components/ios/IosListGroupItem'
import IosNavbar from '../components/ios/IosNavbar'
import useData from '../contexts/data-auth'

const items: {
  title: string
  subtitle: string
  amount: DurationInputArg1
  unit: DurationInputArg2
}[] = [
  { title: 'Box 1', subtitle: 'Daily', amount: 0, unit: 'days' },
  { title: 'Box 2', subtitle: 'After 1 Day', amount: 1, unit: 'day' },
  { title: 'Box 3', subtitle: 'After 3 Days', amount: 3, unit: 'days' },
  { title: 'Box 4', subtitle: 'After 1 Week', amount: 1, unit: 'week' },
  { title: 'Box 5', subtitle: 'After 2 Weeks', amount: 2, unit: 'weeks' },
  { title: 'Box 6', subtitle: 'After 1 Month', amount: 1, unit: 'month' },
  { title: 'Box 7', subtitle: 'After 3 Months', amount: 3, unit: 'months' },
  { title: 'Box 8', subtitle: 'After 6 Months', amount: 6, unit: 'months' },
  { title: 'Box 9', subtitle: 'After 1 Year', amount: 1, unit: 'year' },
]

export default function Boxes() {
  const { data, setBooks } = useData()
  const [vocabulariesInBox, setVocabulariesInBox] = useState<
    VocabularyDetails[][]
  >([])
  const [vocabulariesInBoxToLearn, setVocabulariesInBoxToLearn] = useState<
    VocabularyDetails[][]
  >([])

  useEffect(() => {
    const vocabulariesInBox: VocabularyDetails[][] = []
    for (let index = 0; index < items.length + 2; index++) {
      vocabulariesInBox[index] = data.books
        .flatMap((books) => books.vocabularies)
        .filter((vocabulary) => vocabulary.box === index)
    }
    for (let index = 0; index < items.length + 2; index++) {
      vocabulariesInBoxToLearn[index] = vocabulariesInBox[index].filter(
        (vocabulary) =>
          moment().isAfter(
            moment(vocabulary.lastQueriedOn)
              .startOf('day')
              .add(
                items[index].amount as DurationInputArg1,
                items[index].unit as DurationInputArg2,
              ),
          ),
      )
    }

    console.log(vocabulariesInBox)
    setVocabulariesInBox(vocabulariesInBox)
  }, [])

  return (
    <>
      <IosNavbar title="Learn" />

      {vocabulariesInBox.length > 0 && (
        <>
          <IosListGroup>
            {React.Children.toArray(
              items.map((item, index) => (
                <IosListGroupItem
                  key={item.title}
                  title={item.title}
                  subtitle={item.subtitle}
                  icon={ArchiveIcon}
                  iconColor={'bg-rose-500'}
                  extra={`${vocabulariesInBoxToLearn[index].length} / ${vocabulariesInBox[index].length}`}
                  to={`/boxes/${index}`}
                />
              )),
            )}
          </IosListGroup>

          <IosListGroup>
            <IosListGroupItem
              title={`Box ${items.length + 1}`}
              subtitle={'Gelernt'}
              icon={ArchiveIcon}
              iconColor={'bg-rose-500'}
              extra={`${vocabulariesInBoxToLearn[items.length].length} / ${vocabulariesInBox[items.length].length}`}
              to={`/boxes/${items.length}`}
            />
          </IosListGroup>
        </>
      )}

      <IosBottomContext
        description={
          vocabulariesInBoxToLearn.flatMap((x) => x).length > 0
            ? `Repeat ${vocabulariesInBoxToLearn.flatMap((x) => x).length} ${
                vocabulariesInBoxToLearn.flatMap((x) => x).length === 1
                  ? 'Vocabulary'
                  : 'Vocabularies'
              } today`
            : 'Nothing to do today :)'
        }
      />
    </>
  )
}
